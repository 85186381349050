
import { onMounted, ref } from 'vue';
import PanelGroup from './components/PanelGroup.vue';
import LineChartRevenue from './components/LineChartRevenue.vue';
import CompanyDataList from './components/CompanyDataList.vue';
import dayjs from 'dayjs';

import {
  getCompanyDataDaily,
  CompanyDataDailyResponse,
  AnalyticQuery,
  triggerExportDailyRevenueCsv
} from '@/services/api';

export default {
  components: {
    PanelGroup,
    LineChartRevenue,
    CompanyDataList
  },
  setup() {
    const selectedDomain = ref('');
    const domainOptions = ref(['asia', 'jerkaoke', 'delphine', 'favemode', 'modelgaydar', 'pov', 'floor17']);

    const companyDataDaily = ref<CompanyDataDailyResponse['data']>({});
    const defaultStartTime = dayjs().subtract(6, 'day').format('YYYY-MM-DD');
    const defaultEndTime = dayjs().format('YYYY-MM-DD');
    const dateRange = ref([defaultStartTime, defaultEndTime]);
    const currentPage = ref(1);
    const sortOrder = ref({
      order: 'desc',
      prop: ''
    });

    const fetchCompanyDataDaily = async({
      page = 1,
      sort = 'desc',
      orderBy
    }: AnalyticQuery = {}) => {
      currentPage.value = page;

      const { data } = await getCompanyDataDaily({
        query: {
          // 2021-12-05 2021-12-31
          ...(dateRange.value && {
            date: `${dateRange.value[0]} ${dateRange.value[1]}`
          }),
          domain: selectedDomain.value,
          page,
          sort,
          orderBy
        }
      });
      companyDataDaily.value = data;
    };

    const handlePageChange = (newPage: number) => {
      fetchCompanyDataDaily({ page: newPage });
    };

    const onDomainChange = (newDomain) => {
      selectedDomain.value = newDomain;
      fetchCompanyDataDaily();
    };

    const handleSortChange = (row: any) => {
      sortOrder.value.order = row.order === 'ascending' ? 'asc' : 'desc';
      sortOrder.value.prop = row.prop;
      fetchCompanyDataDaily({
        page: currentPage.value,
        sort: sortOrder.value.order,
        orderBy: sortOrder.value.prop
      });
    };

    // 原組件使用function
    // const setDateRange = (_dateRange: string[]) => {
    //   dateRange.value = _dateRange;
    //   fetchRevenueDaily();
    // };

    const setDateRange = () => {
      fetchCompanyDataDaily();
    };

    const exportDailyRevenueCsv = async() => {
      console.log('export daily revenue csv');
      const exportSuccessful = await triggerExportDailyRevenueCsv();

      if (exportSuccessful) {
        // Handle success (e.g., show a success message)
        console.log('export csv success', exportSuccessful);
      } else {
        // Handle failure (e.g., show an error message)
        console.log('export csv fail', exportSuccessful);
      }
    };

    onMounted(async() => {
      fetchCompanyDataDaily();
    });

    return {
      dateRange,
      fetchCompanyDataDaily,
      handlePageChange,
      companyDataDaily,
      setDateRange,
      handleSortChange,
      exportDailyRevenueCsv,
      onDomainChange,
      selectedDomain,
      domainOptions
    };
  }
};
